import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../templates/Layout";


export const pageQuery = graphql`
    query GetInTouch {
        mdx(slug: {eq: "contact-us"}) {
            body
            frontmatter {
              title
            }
        }
    }
`


class GetInTouch extends React.Component {
    render() {
        const data = this.props.data.mdx;

        return (
            <Layout
                title={data.frontmatter.title}
                location={this.props.location}
            >
                <iframe style={{width: "100%", minHeight: "1400px"}} title="contact form" src="https://docs.google.com/forms/d/e/1FAIpQLSecaLIs3mPf620IavFHvWPV19lsGGUZ-mhkxMHmNyfJWRSKlw/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </Layout>
        )
    }
}
        
export default GetInTouch